import React, { useContext, useState, useEffect } from "react";
import Countdown from "components/Countdown";
import "./style.scss";
import FluidVideoBackground from "components/FluidVideoBackground";
import FluidVignette from "components/FluidVignette";
import { Fade } from "react-awesome-reveal";
import Loading from "components/Loading";
import sanityClient from "components/sanityClient";

export default function View({
  eventDataContext,
  title,
  subtitle,
  backgroundVideo,
}: any) {
  const [videoSRC, setVideoSRC] = useState<any>();
  useEffect(() => {
    const videoRef = backgroundVideo?.asset?._ref;
    if (!videoRef) return;
    //use .getDocument method on sanity client to get the video url from the video reference
    sanityClient
      .getDocument(videoRef)
      .then((asset: any) => {
        setVideoSRC(asset.url);
      })
      .catch((err: any) => console.error);
  }, []);
  const { events } = useContext<any>(eventDataContext);
  if (!videoSRC) return <Loading />;
  return (
    <div className="landing-screen-container">
      <FluidVideoBackground src={videoSRC} />
      <FluidVignette />
      <section className="content-container">
        <div className="center-content">
          <Fade direction="up">
            <h1>{title || "Failed to Load Title"}</h1>
          </Fade>
          <Fade direction="up">
            <h3>{subtitle || "Failed to load subtitle"}</h3>
          </Fade>
          {events && events.liveEvent ? (
            <Fade className="watch-us-live-button" direction="up">
              <a className="button" href="/live">
                Watch Us Live
              </a>
            </Fade>
          ) : (
            <></>
          )}
        </div>
        {/* <div className='countdown-area-container'>
					{events ? (
						!events.liveEvent ? (
							<>
								<h3>WATCH US LIVE IN</h3>
								<Countdown eventDataContext={eventDataContext} />
							</>
						) : (
							<></>
						)
					) : (
						<>loading</>
					)}
				</div> */}
      </section>
    </div>
  );
}
