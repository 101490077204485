import { useState } from "react";
import "./email.scss";
import { TitleText } from "components/Generics";
import mailServer from "components/Utilities/emailServer";

function Email(props: any) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <TitleText
      className="email_container"
      title={props.title || "Contact Us"}
      text="We are excited to hear from you and answer any questions you may have.
    To get in touch, please fill out the contact form below and one of our
    team members will respond to you as soon as possible.You can also reach
    us by phone at (310) 676-3293 or by email at centinelabible@outlook.com."
    >
      {/* @ts-ignore */}
      <label htmlFor={"email"}>Email</label>
      <textarea
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        id={"email"}
      ></textarea>
      {/* @ts-ignore */}
      <label htmlFor={"message"}>message</label>
      <textarea
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        id={"message"}
      ></textarea>
      <button
        onClick={() => {
          let body = JSON.stringify({
            toEmail: props.sendTo,
            fromEmail: email,
            subject: "New email from Centinelabible.org!",
            message: message,
          });

          console.log("body", body);

          fetch(`${mailServer}/sendMail`, {
            method: "post",
            body: body,
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res: any) => {
              return res.json();
            })
            .then((status: any) => {
              console.log("status data below");
              console.log(status);
              alert("Email sent successfully!");
              return Promise.resolve(status);
            })
            .catch((err: any) => {
              console.error(err);
              alert("Failed to send Email");
            });
        }}
      >
        Send
      </button>
    </TitleText>
  );
}

export default Email;
