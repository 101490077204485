import { useState, useEffect } from "react";
import sanityClient from "components/sanityClient";

function useNav() {
  const [navItems, setNavItems] = useState<any>(null);

  useEffect(() => {
    const query = `*[_type == "nav"][0]{...}`;
    sanityClient
      .fetch(query)
      .then(async (data: any) => {
        const formatedNav = await (async () => {
          const navItems = data.nav;
          const formatedNav = [];
          for (let i = 0; i < navItems.length; i++) {
            const navItem = navItems[i];
            if (navItem._type === "linkItem") {
              formatedNav.push(navItem);
              continue;
            }
            const query = `*[_type == "page" && _id == "${navItem._ref}"][0]`;
            const page = await sanityClient.fetch(query);
            formatedNav.push(page);
          }
          return formatedNav;
        })();
        setNavItems(formatedNav);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, []);

  return navItems;
}

export default useNav;
