import React from "react";
import { AttentionSeeker, Slide } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import useDocument from "components/useDocument";
import "./style.scss";
export default function View({ image, title }: any) {
  const imageDoc = useDocument(image?.asset?._ref);
  const sm = useMediaQuery({ query: "(max-width: 	768px)" });
  return (
    <div
      className="pivital-display-02-container"
      style={{
        backgroundColor: sm ? "#757575" : "transparent",
      }}
    >
      <section className="image-section">
        <div className="img-bg-block"></div>
        <Slide direction="left">
          {imageDoc ? (
            <img src={imageDoc?.url} />
          ) : (
            <div>Failed to load image</div>
          )}
        </Slide>
      </section>
      <section className="text-section">
        <div className="center-content">
          <Slide direction="right">
            <AttentionSeeker effect="pulse" delay={450}>
              <h2>{title}</h2>
            </AttentionSeeker>
          </Slide>
        </div>
      </section>
    </div>
  );
}
