import React from "react";
import FluidVignette from "components/FluidVignette";
import "./style.scss";
export default function View({ src }: any) {
  return (
    <div className="fixed-background-container">
      <img src={src} />
    </div>
  );
}
