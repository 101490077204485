import { useContext, useEffect } from "react";
import RouterContext from "components/Router";
import LandingScreen from "components/LandingScreen";
import PivitalDisplay from "components/PivitalDisplay";
import PivitalDisplay02 from "components/PivitalDisplay02";
import FixedBackground from "components/FixedBackground";
import Decition from "components/Decition";
import LiveStream from "components/LiveStream";
import eventDataContext from "components/EventData/EventDataContext";
import "./style.scss";
import settingsContext from "components/Settings";
import useDocument from "components/useDocument";
import Email from "components/Email";
import Map from "components/Map";
import TitleText from "components/TitleText";
import StandaloneText from "components/StandaloneText";

//All context must be passed in as a prop
//Because design pattern will be required to intigrate with the layouter-tools package in the future

export default function Body() {
  const { page } = useContext<any>(RouterContext);
  const settings = useContext<any>(settingsContext);
  const backgroundImageDoc = useDocument(
    settings?.siteBackgroundImage?.asset?._ref
  );

  if (!page) return <div>Loading...</div>;
  return (
    <div className="body-container">
      {page.sections.map((section: any, i: number) => {
        switch (section._type) {
          case "landingScreen":
            return (
              <LandingScreen
                key={i}
                {...section}
                eventDataContext={eventDataContext}
              />
            );
          case "pivotalDisplay":
            return <PivitalDisplay key={i} {...section} />;
          case "pivotalDisplay2":
            return <PivitalDisplay02 key={i} {...section} />;
          case "branch":
            return <Decition key={i} {...section} />;
          case "video":
            return (
              <LiveStream
                eventDataContext={eventDataContext}
                key={i}
                {...section}
              />
            );
          case "emailForm":
            return <Email key={i} {...section} />;
          case "textDisplay":
            return <TitleText key={i} {...section} />;
          case "map":
            return <Map key={i} {...section} />;
          case "standaloneText":
            return <StandaloneText key={i} {...section} />;
          default:
            return <div key={i}>Unknown section type: {section._type}</div>;
        }
      })}
      {/* TODO: create settings context and have FixBackground read from it */}
      {backgroundImageDoc ? (
        <div className="bg-container">
          <FixedBackground src={backgroundImageDoc.url} />
        </div>
      ) : (
        <></>
      )}
      <StandaloneText />
    </div>
  );
}
