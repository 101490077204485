/* Is called video in Santiy studio */

import Countdown from "components/Countdown";
import { useEffect, useContext } from "react";
import "./style.scss";
export default function View({ eventDataContext }: any) {
  const { events, refreshPage } = useContext<any>(eventDataContext) || {};
  useEffect(() => {
    const windowSize = (() => {
      const width = window.innerWidth;
      if (width <= 640) return "xs";
      else if (width <= 768) return "sm";
      else if (width <= 1024) return "md";
      else if (width <= 1280) return "lg";
      else if (width <= 1536) return "xl";
      else return "2xl";
    })();
    const dimentions = (() => {
      switch (windowSize) {
        case "xs":
          return { width: 334, height: 530 };
        case "sm":
          return { width: 604, height: 530 };
        case "md":
          return { width: 550, height: 280 };
        case "lg":
          return { width: 734, height: 350 };
        case "xl":
          return { width: 854, height: 480 };
        case "2xl":
          return { width: 1054, height: 680 };
        default:
          return { width: 1454, height: 1080 };
      }
    })();
    // @ts-ignore
    new window.Twitch.Embed("twitch-embed", {
      width: dimentions.width,
      height: dimentions.height,
      channel: "centinelabiblechurch",
    });
  }, []);

  return (
    <div className="live-stream-container">
      <section className="content-side">
        <div className="twitch-container" id="twitch-embed"></div>

        {/* {events && events.liveEvent ? (
					<>
						<p className='subtext'>CURRENT BROADCAST</p>
						<h3>{events.liveEvent.title}</h3>
						<p>{events.liveEvent.description}</p>
					</>
				) : (
					<>
						<h3>WATCH US LIVE IN</h3>
						<Countdown eventDataContext={eventDataContext} />
					</>
				)} */}
      </section>
      {/* <section className='schedual-side'>
				{events ? (
					events.allEvents.reverse().map((event: any) => {
						return (
							<>
								<h3>{event.formatedStartDate}</h3>
								<div className='scedual-container'>
									<h4>{event.title}</h4>
									<p dangerouslySetInnerHTML={{ __html: event.description }}></p>
								</div>
							</>
						);
					})
				) : (
					<p>No events scedualed</p>
				)}
			</section> */}
    </div>
  );
}
