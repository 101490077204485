import eventDataContext from "./EventDataContext";
import React, { useState, useEffect } from "react";
export default function EventDataProvider({ children }: any) {
  const origin = "https://centinela-backend.herokuapp.com";
  const [events, setEvents] = useState();
  const [refreshCount, setRefreshCount] = useState(0);
  const refreshPage = () => {
    setRefreshCount(refreshCount + 1);
    //@ts-ignore
    setEvents(null);
  };
  useEffect(() => {
    if (events) return;
    /* fetch(`${origin}/events/live-service`, {
			method: 'GET',
		})
			.then(res => res.json())
			.then(res => {
				console.log('Updating site with event data', res);
				setEvents(res);
			})
			.catch(console.error); */
  }, [events]);
  return (
    <eventDataContext.Provider value={{ events, refreshPage }}>
      {children}
    </eventDataContext.Provider>
  );
}
