import "./App.css";
import Header from "core/Header";
import Body from "core/Body";
import Footer from "core/Footer";
import EventData from "components/EventData";
import { RouterProvider } from "components/Router";
import { SettingsProvider } from "components/Settings";

function App() {
  return (
    <EventData>
      <RouterProvider>
        <SettingsProvider>
          <div className="App">
            <Header />
            <Body />
            <Footer />
          </div>
        </SettingsProvider>
      </RouterProvider>
    </EventData>
  );
}

export default App;
