import { Slide } from "react-awesome-reveal";
import "./standaloneText.scss";

const development = false;

function StandaloneText({ title, text, titleSlide, textSlide }: any) {
  if (development) {
    title = "Lorem ipsum dolor sit amet consectetur adipisicing elit.";
    text =
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias doloribus excepturi quam ratione adipisci velit tempora tempore molestiae, mollitia facere cupiditate laborum delectus dolore reiciendis laboriosam. Quo nemo temporibus dicta.";
    titleSlide = "none";
    textSlide = "none";
  }

  if (!title && !text) return <></>;
  return (
    <div className="standalone-text-container">
      {titleSlide !== "none" ? (
        <Slide direction={titleSlide}>
          <h2>{title}</h2>
        </Slide>
      ) : (
        <h2>{title}</h2>
      )}
      {textSlide !== "none" ? (
        <Slide direction={textSlide}>
          <p className={title ? `pt-4` : ""}>{text}</p>
        </Slide>
      ) : (
        <p className={title ? `pt-4` : ""}>{text}</p>
      )}
    </div>
  );
}

export default StandaloneText;
