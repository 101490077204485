import { useState, useEffect } from "react";
import sanityClient from "components/sanityClient";

export default function useDocument(ref: string) {
  const [document, setDocument] = useState<any>(null);
  useEffect(() => {
    sanityClient
      .getDocument(ref)
      .then((data: any) => {
        setDocument(data);
      })
      .catch((err: any) => console.error);
  }, [ref]);

  return document;
}
