import { useEffect } from "react";
import "./map.scss";
import { TitleText } from "components/Generics";
declare global {
  interface Window {
    initMap: () => void;
    google: any;
  }
}

function Map({ title, text, mapApiKey, latitude, longitude, zoom }: any) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&v=weekly`;

    script.onload = () => {
      let map;
      let service;
      let infowindow;
      //@ts-ignore
      let google = window.google;
      // Initialize and add the map
      function initMap(): void {
        // The location of Uluru
        const uluru = { lat: latitude, lng: longitude };
        // The map, centered at Uluru
        //@ts-ignore
        const map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            zoom,
            center: uluru,
          }
        );

        // The marker, positioned at Uluru
        //@ts-ignore
        const marker = new google.maps.Marker({
          position: uluru,
          map: map,
        });
      }

      initMap();
    };

    document.body.appendChild(script);
  }, [window.google]);

  return (
    <TitleText title={title} text={text} className={`map_container`}>
      <div id="map"></div>
    </TitleText>
  );
}

export default Map;
