import "./semiContainer.scss";

function SemiContainer({ className, children }: any) {
  return (
    <div className={`semi_container ${className}`}>
      <div className="inner_container">{children}</div>
    </div>
  );
}

export default SemiContainer;
