import { useState, useEffect } from "react";
import { Fade, Flip, Slide } from "react-awesome-reveal";
import "./style.scss";
import { FACEBOOK_LINK } from "config";
import useDocument from "components/useDocument";
import useActionButton from "components/useActionButton";
export default function View({ title, preTitle, branch1, branch2 }: any) {
  const decision1ImgDoc = useDocument(branch1?.image?.asset?._ref);
  const decision2ImgDoc = useDocument(branch2?.image?.asset?._ref);
  const decision1ActionButtonEssentials = useActionButton(
    branch1?.actionButton
  );
  const decision2ActionButtonEssentials = useActionButton(
    branch2?.actionButton
  );

  useEffect(() => {}, []);

  return (
    <div className="decition-container">
      <div className="center-content">
        <div className="titles-section">
          <Slide direction="up">
            <p className="subheading">{preTitle}</p>
          </Slide>
          <Fade delay={50}>
            <h1>{title}</h1>
          </Fade>
        </div>
        <Slide direction="left">
          <hr />
        </Slide>
        <ul className="decitions">
          {decision1ImgDoc && decision1ActionButtonEssentials ? (
            <li>
              <a
                target={decision1ActionButtonEssentials.target}
                href={decision1ActionButtonEssentials.href}
                onClick={decision1ActionButtonEssentials.onClick}
              >
                <Slide direction="left">
                  <p>{branch1.actionButton.title}</p>
                </Slide>
                <Flip direction="horizontal">
                  <img src={decision1ImgDoc.url} />
                </Flip>
              </a>
            </li>
          ) : (
            <div>Failed to load Item</div>
          )}
          {decision2ImgDoc && decision2ActionButtonEssentials ? (
            <li>
              <a
                target={decision2ActionButtonEssentials.target}
                href={decision2ActionButtonEssentials.href}
                onClick={decision2ActionButtonEssentials.onClick}
              >
                <Slide direction="left">
                  <p>Youtube</p>
                </Slide>
                {decision2ActionButtonEssentials ? (
                  <Flip direction="horizontal" delay={100}>
                    <img src={decision2ImgDoc.url} />
                  </Flip>
                ) : (
                  <div>Error loading component</div>
                )}
              </a>
            </li>
          ) : (
            <div>Faild to load Item</div>
          )}
        </ul>
      </div>
    </div>
  );
}
