import React, { useLayoutEffect } from "react";
import "./style.scss";

export default function View({ src }: any) {
  return (
    <div className="fluid-video-container">
      <video
        className="fluid-video-background"
        autoPlay
        playsInline
        muted
        loop
        src={src}
      >
        This is the fluid bg
      </video>
    </div>
  );
}
