import isDev from "./isDev";
import devSettings from "DevSettings";

const productionEmailServer =
  "https://centinelaemail.singularitydevelopment.com";
const developmentEmailServer = "http://localhost:3003";

const emailServer = devSettings.force_production
  ? productionEmailServer
  : isDev()
  ? developmentEmailServer
  : productionEmailServer;

export default emailServer;
