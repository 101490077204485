import SemiContainer from "./SemiContainer";

function TitleText({ className, title, text, children }: any) {
  return (
    <SemiContainer className={className}>
      {title ? <h1>{title}</h1> : <></>}
      {text ? <p>{text}</p> : <></>}
      {children}
    </SemiContainer>
  );
}

export default TitleText;
