import "./style.scss";

function NavMenu({ onToggle, navItems, setRoute }: any) {
  return (
    <div className="Nav-Menu-container">
      <div className="nav-container">
        <ul className="left-nav">
          <li className="brand">
            <a href="/">
              <img src="/logos&icons/dark_logo.png" />
            </a>
          </li>
        </ul>
        <ul className="right-nav  mobile-right-nav">
          <li>
            <button onClick={onToggle}>
              <img src="logos&icons/dark_hamburger.svg" alt="toggle nav menu" />
            </button>
          </li>
        </ul>
      </div>
      <ul className="nav-menu-items-container">
        {navItems && navItems[0] ? (
          navItems.map((navItem: any, i: number) => {
            if (navItem._type === "linkItem")
              return (
                <li key={i}>
                  <a className="hover_me" href={navItem.url}>
                    {navItem.title}
                  </a>
                </li>
              );
            else
              return (
                <li key={i}>
                  <a
                    className="hover_me"
                    onClick={() => {
                      setRoute(navItem.slug.current);
                    }}
                  >
                    {navItem.name}
                  </a>
                </li>
              );
          })
        ) : (
          <div>Loading</div>
        )}
      </ul>
    </div>
  );
}

export default NavMenu;
