import { useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import "./style.scss";
import { FACEBOOK_LINK } from "config";
import useActionButton from "components/useActionButton";
import sanityClient from "components/sanityClient";

export default function View({ title, text, actionButton, image }: any) {
  const sm = useMediaQuery({ query: "(max-width: 	768px)" });
  const actionButtonEssentials = useActionButton(actionButton);
  const [imageSRC, setImageSRC] = useState<any>();

  useEffect(() => {
    const imageRef = image?.asset?._ref;
    if (!imageRef) return;
    sanityClient
      .getDocument(imageRef)
      .then((asset: any) => {
        setImageSRC(asset.url);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="pivital-display-container">
      <section className="text-section">
        <div className="center-content">
          {sm ? (
            <section className="image-section">
              <Slide direction="right">
                <img src="/images/pivital-display-01.png" />
              </Slide>
            </section>
          ) : (
            <></>
          )}
          <Slide direction="left">
            <h2>{title}</h2>
          </Slide>
          <hr />
          <Slide direction="left" delay={100}>
            <p>{text}</p>
          </Slide>
          <Slide direction="left" delay={200}>
            <div className="button-container">
              {actionButtonEssentials ? (
                <a
                  target={actionButtonEssentials.target}
                  onClick={actionButtonEssentials.onClick}
                  href={actionButtonEssentials.href}
                  className="button"
                >
                  {actionButtonEssentials.title
                    ? actionButtonEssentials.title
                    : `Learn More`}
                </a>
              ) : (
                <></>
              )}
            </div>
          </Slide>
        </div>
      </section>
      {!sm ? (
        <section className="image-section">
          <Slide direction="right">
            {imageSRC ? (
              <img src={imageSRC} />
            ) : (
              <div>Failed to load image</div>
            )}
          </Slide>
        </section>
      ) : (
        <></>
      )}
    </div>
  );
}
