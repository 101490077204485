import { createContext, useEffect, useState } from "react";
import sanityClient from "components/sanityClient";

const settingsContext = createContext({});

export default settingsContext;

export function SettingsProvider({ children }: any) {
  const [settings, setSettings] = useState<any>();
  useEffect(() => {
    const query = `*[_type == "settings"][0]`;
    sanityClient
      .fetch(query)
      .then((data) => {
        setSettings(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <settingsContext.Provider value={settings}>
      {children}
    </settingsContext.Provider>
  );
}
