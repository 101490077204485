import { useContext } from "react";
import "./style.scss";
import Countdown from "components/Countdown";
import { useMediaQuery } from "react-responsive";
import { Slide } from "react-awesome-reveal";
import eventDataContext from "components/EventData/EventDataContext";
import { FACEBOOK_LINK } from "config";
import useNav from "components/Nav/useNav";
import useActionButton from "components/useActionButton";
import Router from "components/Router";
import settingsContext from "components/Settings";
import useDocument from "components/useDocument";

export default function Footer({}: any) {
  const { events } = useContext(eventDataContext);
  const { setRoute } = useContext<any>(Router);
  const settings: any = useContext(settingsContext);
  const sm = useMediaQuery({ query: "(max-width: 	768px)" });
  const navItems: any = useNav();

  const {
    footerMessage,
    footerSubmessage,
    phoneNumber,
    email,
    address,
    social1,
    social2,
    footerBackgroundImage,
    adminLink,
    addressLink,
  } = settings || {};

  const social1ImgDoc: any = useDocument(social1?.icon?.asset?._ref);
  const social2ImgDoc: any = useDocument(social2?.icon?.asset?._ref);
  const backgroundImgDoc: any = useDocument(footerBackgroundImage?.asset?._ref);

  if (!settings) return <div>Loading</div>;

  return (
    <>
      <div
        className="footer-container"
        style={{
          backgroundImage: `url(${backgroundImgDoc?.url})`,
        }}
      >
        {/* <img className='bg' src='/images/footer_bg.png' /> */}
        <div className="upper-content">
          <section className="center-content">
            <div className="center-content">
              <section className="header-container">
                <Slide direction="left">
                  <span className="green-block"></span>
                </Slide>
                <Slide direction="right">
                  <h2>{footerMessage}</h2>
                </Slide>
              </section>
              {/* <div className='footer-countdown-container'>
								{events && events.liveEvent ? (
									<a className='button watch-live-button' href='/live'>
										Watch Us Live
									</a>
								) : (
									<Countdown eventDataContext={eventDataContext} />
								)}
							</div> */}
            </div>
          </section>
        </div>
        <section className="lower-content">
          <section className="logo">
            <img src="/logos&icons/dark_logo.png" />
          </section>
          {!sm ? (
            <>
              <section className="nav">
                <Slide className="center-text" direction="up">
                  <h3>{footerSubmessage}</h3>
                </Slide>

                <ul className="links hor-list">
                  {navItems && navItems[0] ? (
                    (() => {
                      const navItem = navItems[0];
                      if (navItem._type === "linkItem")
                        return (
                          <li key={6}>
                            <Slide direction="up" delay={100}>
                              <a className="hover_me" href={navItem.url}>
                                {navItem.title}
                              </a>
                            </Slide>
                          </li>
                        );
                      else
                        return (
                          <li key={7}>
                            <Slide direction="up">
                              <a
                                className="hover_me"
                                onClick={() => {
                                  setRoute(navItem.slug.current);
                                }}
                              >
                                {navItem.name}
                              </a>
                            </Slide>
                          </li>
                        );
                    })()
                  ) : (
                    <div>Error Loading Footer</div>
                  )}
                  {/* <li>
                    <Slide direction="up" delay={300}>
                      <a href={adminLink}>Admin</a>
                    </Slide>
                  </li> */}
                </ul>
                <ul className="icons hor-list">
                  <li>
                    <Slide direction="up" delay={200}>
                      <a href={social1.url}>
                        <img src={social1ImgDoc?.url} />
                      </a>
                    </Slide>
                  </li>
                  <li>
                    <Slide direction="up" delay={300}>
                      <a href={social2.url}>
                        <img src={social2ImgDoc?.url} />
                      </a>
                    </Slide>
                  </li>
                </ul>
              </section>
              <section className="contact">
                <ul>
                  <li>
                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </li>
                  <li>
                    <a href={`mailto:${email}`} target={"_blank"}>
                      {email}
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={addressLink}>
                      {address}
                    </a>
                  </li>
                </ul>
              </section>
            </>
          ) : (
            <></>
          )}
        </section>
      </div>
      {sm ? (
        <div className="extra-footer-container">
          <Slide className="center-text" direction="up">
            <h3>{footerSubmessage}</h3>
          </Slide>
          <section className="nav">
            <ul className="links hor-list">
              {navItems && navItems[0] ? (
                (() => {
                  const navItem = navItems[0];
                  if (navItem._type === "linkItem")
                    return (
                      <li key={6}>
                        <Slide direction="up" delay={100}>
                          <a className="hover_me" href={navItem.url}>
                            {navItem.title}
                          </a>
                        </Slide>
                      </li>
                    );
                  else
                    return (
                      <li key={7}>
                        <Slide direction="up">
                          <a
                            className="hover_me"
                            onClick={() => {
                              setRoute(navItem.slug.current);
                            }}
                          >
                            {navItem.name}
                          </a>
                        </Slide>
                      </li>
                    );
                })()
              ) : (
                <div>Error loading items</div>
              )}
            </ul>
            <ul className="icons hor-list">
              <li>
                <Slide direction="up" delay={200}>
                  <a href={social1.url}>
                    <img src={social1ImgDoc?.url} />
                  </a>
                </Slide>
              </li>
              <li>
                <Slide direction="up" delay={200}>
                  <a href={social2.url}>
                    <img src={social2ImgDoc?.url} />
                  </a>
                </Slide>
              </li>
            </ul>
          </section>
          <section className="contact">
            <ul>
              <li>
                <Slide direction="up" delay={200}>
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </Slide>
              </li>
              <li>
                <Slide direction="up" delay={200}>
                  <a href={`mailto:${email}`} target={"_blank"}>
                    {email}
                  </a>
                </Slide>
              </li>
              <li>
                <Slide direction="up" delay={200}>
                  <a target="_blank" href={addressLink}>
                    {address}
                  </a>
                </Slide>
              </li>
            </ul>
          </section>
        </div>
      ) : (
        <></>
      )}
      <div className="py-4 w-full bg-footer-color">
        <p>
          Centinela Bible Church is a 501c3 non-profit corporation registerd in
          the US #95-1890714
        </p>
      </div>
    </>
  );
}
