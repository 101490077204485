import { createContext, useState, useEffect } from "react";
import sanityClient from "components/sanityClient";
const RouterContext = createContext({});
export default RouterContext;

export function RouterProvider({ children }: any) {
  const [currentRoute, setCurrentRoute] = useState<any>();
  const [page, setPage] = useState<any>();

  // Make current Route represent webpages current route on page load.
  useEffect(() => {
    setCurrentRoute(window.location.pathname);
  }, []);

  useEffect(() => {
    // Query sanity for page data
    // Where type is page and slug is currentRoute (without the /)
    // Set pages to the result

    if (!currentRoute) return;
    window.history.replaceState({}, "Site navigation", currentRoute);
    let slug = currentRoute.split("/")[1];
    if (slug === "") slug = "home";
    const query = `*[_type == "page" && slug.current == "${slug}"][0]{..., sections[]->}`;
    sanityClient
      .fetch(query)
      .then((data) => {
        setPage(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentRoute]);

  return (
    <RouterContext.Provider
      value={{ currentRoute, setCurrentRoute, setRoute, page }}
    >
      {children}
    </RouterContext.Provider>
  );

  function setRoute(page: string) {
    setCurrentRoute(`/${page}`);
  }
}
