import { useContext, useState } from "react";
import "./style.scss";
import RouterContext from "components/Router";
import useNav from "./useNav";
import { useMediaQuery } from "react-responsive";
import NavMenu from "./NavMenu";

export default function Nav() {
  const navItems: any = useNav();
  const { setRoute } = useContext<any>(RouterContext);
  const [showMenu, setShowMenu] = useState(false);
  const sm = useMediaQuery({ query: "(max-width: 	768px)" });

  return (
    <div className="nav-container">
      {showMenu ? (
        <NavMenu
          onToggle={() => {
            setShowMenu(!showMenu);
          }}
          navItems={navItems}
          setRoute={setRoute}
        />
      ) : (
        <></>
      )}
      <ul className="left-nav">
        <li className="brand">
          <a href="/">
            <img src="/logos&icons/dark_logo.png" />
          </a>
        </li>
      </ul>
      <RightNav />
    </div>
  );

  function RightNav() {
    return sm ? <MobileRightNav /> : <DesktopRightNav />;
  }

  function DesktopRightNav() {
    return (
      <ul className="right-nav">
        {navItems && navItems[0] ? (
          navItems.map((navItem: any, i: number) => {
            if (navItem._type === "linkItem")
              return (
                <li key={i}>
                  <a className="hover_me" href={navItem.url}>
                    {navItem.title}
                  </a>
                </li>
              );
            else
              return (
                <li key={i}>
                  <a
                    className="hover_me"
                    onClick={() => {
                      setRoute(navItem.slug.current);
                    }}
                  >
                    {navItem.name}
                  </a>
                </li>
              );
          })
        ) : (
          <div>Error</div>
        )}
      </ul>
    );
  }

  function MobileRightNav() {
    return (
      <ul className="right-nav mobile-right-nav">
        <li>
          <button
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            <img src="logos&icons/dark_hamburger.svg" alt="toggle nav menu" />
          </button>
        </li>
      </ul>
    );
  }
}
