import { useContext, useState, useEffect } from "react";
import RouterContext from "./Router/Router";
import sanityClient from "components/sanityClient";

//Will return a state that is null then hook in not initialized and will return an object with the href and onClick properties when initialized
export default function useActionButton(rawActionButton: any) {
  const [actionButton, setActionButton] = useState<any>(null);
  const { setRoute } = useContext<any>(RouterContext);
  useEffect(() => {
    if (!rawActionButton) return setActionButton(null);
    if (!(rawActionButton.type === "pageChange")) {
      setActionButton({
        href: rawActionButton.link,
        onClick: () => {},
        target: "_blank",
        title: rawActionButton.title,
      });
      return;
    }
    const pageRef = rawActionButton.page._ref;
    //Create a sanityClient query to get the page slug
    const query = `*[_id == "${pageRef}"][0]{"slug": slug.current}`;
    sanityClient
      .fetch(query)
      .then((data) => {
        setActionButton({
          href: undefined,
          onClick: () => {
            window.scrollTo(0, 0);
            setRoute(data.slug);
          },
          target: "",
        });
      })
      .catch((err) => {
        console.error("Error getting page slug", err);
      });
  }, []);

  return actionButton;
}
